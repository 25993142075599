import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { Logo } from './StyledComponents';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const Header = () => {
  const [fingerprint, setFingerprint] = useState<string | null>(null);
  const [welcomeMessage, setWelcomeMessage] = useState<string>('');

  const generateFingerprintName = (fingerprint: string) => {
    const adjectives = ['Suspicious', 'Clever', 'Brave', 'Witty', 'Charming', 'Daring', 'Elegant', 'Fierce', 'Gentle', 'Honest'];
    const nouns = ['Grizzly', 'Boulder', 'Eagle', 'Mountain', 'River', 'Forest', 'Ocean', 'Desert', 'Meadow', 'Canyon'];
    
    const hash = fingerprint.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    const adjectiveIndex = hash % adjectives.length;
    const nounIndex = Math.floor(hash / adjectives.length) % nouns.length;
    
    return `${adjectives[adjectiveIndex]} ${nouns[nounIndex]}`;
  };
  const generateWelcomeMessage = (count: number, name: string) => {
    const messages = [
      `Welcome to the site, ${name}!`,
      `Welcome back, ${name}. Happy to see you're back.`,
      `3rd time's a charm, ${name}!`,
      `4 visits already, ${name}? You must really like it here!`,
      `High 5 for your 5th visit, ${name}!`,
      `6th visit, ${name}? You're becoming a regular!`,
      `Lucky number 7, ${name}!`,
      `8 visits? You're on a roll, ${name}!`,
      `9 times? You're practically family now, ${name}!`,
      `Double digits! Visit #10, ${name}!`,
      `11th visit, ${name}. We should name a seat after you!`,
      `A dozen visits, ${name}! You deserve a trophy!`,
      `Lucky 13, ${name}! Your dedication is admirable.`,
      `14 visits? ${name}, you're crazy!`,
      `15 visits! ${name}, you're my VIP visitor!`,
      `Sweet 16 visits, ${name}! You're growing up so fast!`,
      `17 visits, ${name}. You're old enough to drive this website now!`,
      `18 visits! ${name}, you're an adult in website years!`,
      `19th visit, ${name}. 1 more for a perfect score!`,
      `20 visits! ${name}, you've hit the big 2-0!`,
      `21 visits, ${name}! You can now legally browse in all countries!`,
      `22 visits! ${name}, you're catching up to my version number!`,
      `Visit 23, ${name}! Prime number for a prime visitor!`,
      `24 visits, ${name}? That's 2 dozen reasons we love you!`,
      `Quarter century of visits, ${name}! You're a true fan!`,
      `26 visits, ${name}. You've gone through the alphabet!`,
      `27 visits! ${name}, you're as faithful as a Swiss watch!`,
      `28 visits, ${name}. A full lunar cycle!`,
      `29 visits! ${name}, 1 more and you'll need a new decade counter!`,
      `30 visits, ${name}! You've earned your website diploma!`,
      `31 visits, ${name}. You've seen every day of a monthly cycle!`,
      `32 visits! ${name}, that's 2^5 - you're exponentially awesome!`,
      `33 visits, ${name}. You're at your website prime!`,
      `34 visits! ${name}, you're as reliable as pi (3.4)!`,
      `35 visits, ${name}. You're halfway to 70!`,
      `36 visits! ${name}, you've completed a square of awesomeness!`,
      `37 visits, ${name}. Prime number for a prime visitor!`,
      `38 visits! ${name}, you're great in any degree!`,
      `39 visits, ${name}. 1 more for the big 4-0!`,
      `40 visits! ${name}, you're over the hill of awesome!`,
      `41 visits, ${name}. Prime number for a prime visitor!`,
      `42 visits! ${name}, you've found the answer to everything!`,
      `43 visits, ${name}. You're my favorite prime number!`,
      `44 visits! ${name}, you're multiply awesome!`,
      `45 visits, ${name}. You're halfway to 90!`,
      `46 visits! ${name}, you're rounding up to 50!`,
      `47 visits, ${name}. Prime number for a prime visitor!`,
      `48 visits! ${name}, that's 4 dozen reasons we appreciate you!`,
      `49 visits, ${name}. 7 squared - you're exponentially fantastic!`,
      `50 visits! ${name}, you've hit the half-century mark!`
    ];
    if (count <= 50) {
      return messages[count - 1];
    } else {
      return `Wow, ${name}! ${count} visits? You're an ultimate superfan!`;
    }
  };

  useEffect(() => {
    const getFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      
      // Use only stable components for fingerprinting
      const stableComponents = [
        result.components.canvas,
        result.components.screenResolution,
        result.components.hardwareConcurrency,
        result.components.deviceMemory,
        result.components.colorDepth,
        result.components.timezone,
        result.components.platform,
      ];

      console.log(stableComponents);

      // Create a custom hash from stable components
      const customHash = stableComponents
        .filter(Boolean)
        .map(String)
        .join('|');
      
      const customFingerprint = await hashString(customHash);
      setFingerprint(customFingerprint);
      const name = generateFingerprintName(customFingerprint);

      const response = await fetch('https://api.amiragassi.com/api/fingerprint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          visitorId: customFingerprint,
          reasons: Object.keys(result.components),
        }),
      });
      const data = await response.json();
      setWelcomeMessage(generateWelcomeMessage(data.count, name));
    };
    getFingerprint();
  }, []);

  // Add this helper function to create a hash
  const hashString = async (str: string): Promise<string> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        <Logo>A.A.</Logo>
        <Box sx={{ flexGrow: 1 }} />
        {fingerprint ? (
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <Tooltip title="This is your unique digital fingerprint. Try hiding your identity!">
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                <FingerprintIcon sx={{ mr: 0.5, color: 'primary.main', fontSize: '1.2rem' }} />
                <Typography variant="caption" sx={{ fontFamily: 'monospace' }}>
                  {fingerprint.slice(0, 10)}...
                </Typography>
              </Box>
            </Tooltip>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {welcomeMessage}
            </Typography>
          </Box>
        ) : (
          <CircularProgress size={20} sx={{ mr: 2 }} />
        )}
        <Button color="inherit">Home</Button>
        <Button color="inherit">Skills</Button>
        <Button color="inherit">Projects</Button>
        <Button color="inherit">Contact</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;